import React from 'react';
import ReactGA from 'react-ga';
import './modalVideoGaleria.template.css';
import { ModalHotspotProps } from '../../types/hotspot';
import { getNameById } from '../../libs/marzipano';

interface ModalState {
  selected: number;
}

export default class Modal1 extends React.Component<ModalHotspotProps> {
  state: ModalState = {
    selected: 0,
  };

  select(index: number) {
    this.setState({ selected: index });
  }

  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    const videosThumbnails = this.props.info.videos?.map((v, index) => {
      return (
        <img
          className={index === this.state.selected ? 'selected' : ''}
          key={'vid-thumb-' + index}
          src={`http://img.youtube.com/vi/${v.id}/0.jpg`}
          alt=""
          onClick={() => {
            ReactGA.event({
              category: 'Modal Hotspot',
              action: `Select Video`,
              label: `${getNameById(this.props.sceneId)}[${
                this.props.info.title
              }] - http://img.youtube.com/vi/${v.id}/0.jpg`,
            });
            this.select(index);
          }}
        />
      );
    });

    if (mobile) {
      return (
        <div className={'vg-modal-mobile'}>
          <iframe
            width="956"
            height="538"
            src={
              this.props.info.videos &&
              `https://www.youtube.com/embed/${this.props.info.videos[this.state.selected].id}`
            }
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <p>{this.props.info.videos && this.props.info.videos[this.state.selected].obs}</p>
          <div className="thumbs-container">{videosThumbnails}</div>
        </div>
      );
    } else {
      return (
        <div className={'vg-modal'}>
          <iframe
            width="956"
            height="538"
            src={
              this.props.info.videos &&
              `https://www.youtube.com/embed/${this.props.info.videos[this.state.selected].id}`
            }
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <p>{this.props.info.videos && this.props.info.videos[this.state.selected].obs}</p>
          {this.props.info.videos && this.props.info.videos.length > 1 && (
            <div className="thumbs-container">{videosThumbnails}</div>
          )}
        </div>
      );
    }
  }
}
