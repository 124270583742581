import React, { RefObject } from 'react';
import ReactGA from 'react-ga';
import './mapGoogleMaps.template.css';
import { Map, TileLayer, Marker, Tooltip, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { MappingCoords, MappingSpace } from '../../types/mapping';
import { ReactComponent as DirectionsSVG } from '../../icon/directions-fill.svg';
import { ReactComponent as FullOpenSVG } from '../../icon/open_in_full.svg';
import { ReactComponent as FullCloseSVG } from '../../icon/close_fullscreen.svg';
import { ReactComponent as CloseSVG } from '../../icon/close.svg';
import { ReactComponent as ClosedMapSVG } from '../../icon/place.svg';
import { ReactComponent as GPSSvg } from '../../icon/gps-fixed.svg';
import L, { LatLng, LatLngBounds } from 'leaflet';
import redIconUrl from '../../icon/marker_red.svg';
import greyIconUrl from '../../icon/marker_blue.svg';
import { startAutoRotate } from '../../libs/marzipano';
import { MapProps } from '../../types/maps';
import { svgRecolor } from '../../libs/utils';

interface MapGoogleMapsProps extends MapProps {
  title: string;
  active: boolean;
  language: string | undefined;
  coords: MappingCoords | undefined;
  spacesData: MappingSpace[];
  click: CallableFunction;
}

interface MapGoogleMapsState {
  mode: string;
  bounds: LatLngBounds;
}

interface Coordinates {
  latitude: number;
  longitude: number;
}

export default class MapGoogleMaps extends React.Component<MapGoogleMapsProps> {
  state: MapGoogleMapsState = {
    mode: 'closed',
    bounds: L.latLngBounds([0, 0], [0, 0]),
  };

  mapRef: RefObject<Map> = React.createRef();

  closedRef: RefObject<SVGSVGElement> = React.createRef();

  componentDidMount = () => {
    if (this.closedRef.current) {
      svgRecolor(this.closedRef.current);
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      window.addEventListener('displayMap', ((e: CustomEvent) => {
        const mode = e.detail.display ? 'normal' : 'closed';
        this.setState({ mode: mode });
      }) as EventListener);
    }
    let posArray: LatLng[] = [];
    this.props.spacesData.forEach((s) => {
      if (s.coords) {
        posArray.push(new LatLng(s.coords.lat, s.coords.lng));
      }
    });
    const bounds = L.latLngBounds(posArray);
    const polylines = this.props.sectorsData[this.props.selected.sectorIndex].map?.polylines;
    const pl = this.getPolylines();
    if (polylines && pl) {
      let polyArray: LatLng[] = [];
      pl.forEach((p) => {
        for (let i = 0; i < p.length; i++) {
          polyArray.push(new LatLng(p[i][0], p[i][1]));
        }
      });
      const boundsPoly = bounds.extend(L.latLngBounds(polyArray));
      this.setState({ bounds: boundsPoly });
    } else {
      this.setState({ bounds: bounds });
    }
  };

  componentDidUpdate = () => {
    if (this.closedRef.current) {
      svgRecolor(this.closedRef.current);
    }
  };

  getPolylines = () => {
    const polylines = this.props.sectorsData[this.props.selected.sectorIndex].map?.polylines;
    const settings = this.props.sectorsData[this.props.selected.sectorIndex].map?.pathSettings;
    let pl: number[][][] | undefined = [];
    if (polylines && settings?.lngLat) {
      for (let j = 0; j < polylines.length; j++) {
        let tmp = [];
        for (let i = 0; i < polylines[j].length; i++) {
          tmp.push([polylines[j][i][1], polylines[j][i][0]]);
        }
        pl.push(tmp);
      }
    } else {
      pl = polylines;
    }

    return pl;
  };

  changeMode = (newMode: string) => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    if (mobile) {
      const deactivateMapEvent = new CustomEvent('deactivateMap', {
        detail: {},
      });
      window.dispatchEvent(deactivateMapEvent);
    }
    const stopDescriptionEvent = new CustomEvent('stopDescription', {
      detail: {},
    });
    window.dispatchEvent(stopDescriptionEvent);
    this.setState({ mode: newMode }, () => {
      this.mapRef.current?.leafletElement.invalidateSize();
      this.mapRef.current?.leafletElement.flyToBounds(this.state.bounds, { animate: false });
    });
  };

  redirectDirection = (from: Coordinates, to: MappingCoords) => {
    const origin = from.latitude + ',' + from.longitude;
    const target = to.lat + ',' + to.lng;

    //var target = to.name + "@" + to.lat + "," + to.lng;
    var url = 'https://google.com/maps/dir/' + origin + '/' + target;
    window.open(url, '_blank');
  };

  redirectPlace = (title: string, to: MappingCoords) => {
    //const zoom = to.zoom ? to.zoom : 14;
    /*var url =
      'https://google.com/maps/place/' +
      to.lat +
      '+' +
      to.lng +
      '/@' +
      to.lat +
      ',' +
      to.lng +
      ',' +
      zoom +
      'z';*/
    var url = 'https://www.google.com/maps/search/?api=1&query=' + to.lat + ',' + to.lng;
    window.open(url, '_blank');
  };

  redirectToGoogleMaps = () => {
    const stopDescriptionEvent = new CustomEvent('stopDescription', {
      detail: {},
    });
    window.dispatchEvent(stopDescriptionEvent);
    navigator.permissions.query({ name: 'geolocation' }).then((result: PermissionStatus) => {
      if (result.state === 'granted') {
        navigator.geolocation.getCurrentPosition((pos) => {
          if (this.props.coords) this.redirectDirection(pos.coords, this.props.coords);
        });
      } else if (result.state === 'prompt') {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            if (this.props.coords) this.redirectDirection(pos.coords, this.props.coords);
          },
          () => {
            if (this.props.coords) this.redirectPlace(this.props.title, this.props.coords);
          },
        );
      } else if (result.state === 'denied') {
        if (this.props.coords) this.redirectPlace(this.props.title, this.props.coords);
      }
    });
  };

  resetMap = () => {
    this.mapRef.current?.leafletElement.invalidateSize();
    this.mapRef.current?.leafletElement.flyToBounds(this.state.bounds, { animate: true });
  };

  render = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let directionBtnLbl = 'DIREÇÕES';
    switch (this.props.language) {
      case 'English':
        directionBtnLbl = 'DIRECTIONS';
        break;

      default:
        directionBtnLbl = 'DIREÇÔES';
    }

    const redIcon = L.icon({ iconUrl: redIconUrl, iconSize: [35, 35] });
    const greyIcon = L.icon({ iconUrl: greyIconUrl, iconSize: [25, 25] });

    const polylines = this.props.sectorsData[this.props.selected.sectorIndex].map?.polylines;
    const settings = this.props.sectorsData[this.props.selected.sectorIndex].map?.pathSettings;
    const pl = this.getPolylines();
    const leafletPolyline =
      polylines && pl ? (
        <Polyline
          key="poly-key"
          positions={pl as [number, number][][]}
          color={settings?.color ? settings.color : '#1E7DEB'}
          stroke={true}
          opacity={settings?.opacity ? settings.opacity : 1}
          weight={settings?.weight ? settings.weight : 3}
        />
      ) : null;

    let posArray: LatLng[] = [];
    const markers = this.props.spacesData.map((space: MappingSpace) => {
      if (space.coords) {
        const position = { lat: space.coords.lat, lng: space.coords.lng };
        posArray.push(L.latLng(position));
        if (space.coords === this.props.coords) {
          return (
            <Marker
              key={`marker-${space.id}`}
              position={position}
              icon={redIcon}
              zIndexOffset={100}
            >
              <Tooltip>{space.name}</Tooltip>
            </Marker>
          );
        } else {
          return (
            <Marker
              key={`marker-${space.id}`}
              position={position}
              icon={greyIcon}
              onclick={() => {
                this.props.click(space.id, space.type);
                if (this.state.mode === 'fullscreen' || mobile) {
                  this.changeMode('closed');
                }
              }}
            >
              <Tooltip>{space.name}</Tooltip>
            </Marker>
          );
        }
      } else {
        return null;
      }
    });

    if (this.props.active) {
      const mapHeight = this.state.mode === 'fullscreen' ? '83.8vh' : '22.78vh';
      const mapStyle =
        this.state.mode === 'fullscreen'
          ? { zIndex: 320, width: '93.85vw', opacity: 1 }
          : { width: '20.21vw' };
      const background =
        this.state.mode === 'fullscreen' ? (
          <div
            className="maps-background"
            style={{ zIndex: 319 }}
            onClick={() => {
              this.changeMode('normal');
            }}
          ></div>
        ) : null;
      if (mobile) {
        return this.state.mode === 'normal' ? (
          <div>
            <div
              className="maps-background"
              onClick={() => {
                this.changeMode('closed');
                startAutoRotate();
              }}
            ></div>
            <div className="mobile-maps-wrapper">
              <div className="mobile-btn-container">
                <div
                  className="direction-btn"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Direction Request',
                      action: 'Clicked',
                      label: `${this.props.title}`,
                    });
                    this.redirectToGoogleMaps();
                  }}
                >
                  <DirectionsSVG />
                  <p>{directionBtnLbl}</p>
                </div>
                <GPSSvg
                  onClick={() => {
                    this.resetMap();
                  }}
                />
              </div>
              <Map
                ref={this.mapRef}
                maxZoom={19}
                bounds={this.state.bounds}
                style={{
                  height: '50vh',
                  width: '100%',
                }}
              >
                <TileLayer
                  url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                  attribution="&copy; Google"
                  subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                />
                {markers}
                {leafletPolyline}
              </Map>
            </div>
          </div>
        ) : null;
      } else {
        if (this.state.mode === 'closed') {
          return (
            <div className="closed-map-icon" onClick={() => this.changeMode('normal')}>
              <ClosedMapSVG ref={this.closedRef} />
            </div>
          );
        } else {
          return (
            <div>
              {background}
              <div className="map-wrapper" style={mapStyle}>
                <div className="btn-container">
                  <div
                    className="direction-btn"
                    onClick={() => {
                      ReactGA.event({
                        category: 'Direction Request',
                        action: 'Clicked',
                        label: `${this.props.title}`,
                      });
                      this.redirectToGoogleMaps();
                    }}
                  >
                    <DirectionsSVG />
                    <p>{directionBtnLbl}</p>
                  </div>
                  <GPSSvg
                    onClick={() => {
                      this.resetMap();
                    }}
                  />
                  {this.state.mode === 'fullscreen' ? (
                    <FullCloseSVG onClick={() => this.changeMode('normal')} />
                  ) : (
                    <FullOpenSVG
                      onClick={() => {
                        this.changeMode('fullscreen');
                      }}
                    />
                  )}
                  <CloseSVG onClick={() => this.changeMode('closed')} />
                </div>
                <Map
                  ref={this.mapRef}
                  maxZoom={19}
                  bounds={this.state.bounds}
                  style={{
                    height: mapHeight,
                    width: '100%',
                  }}
                >
                  <TileLayer
                    url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                    attribution="&copy; Google"
                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                  />
                  {markers}
                  {leafletPolyline}
                </Map>
              </div>
            </div>
          );
        }
      }
    } else {
      return null;
    }
  };
}
