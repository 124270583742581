import React from 'react';
import ReactGA from 'react-ga';
import Overlay from '../components/overlay';
import { Logo } from '../components/logo';
import { ExplorerState, ExplorerProps } from '../types/explorer';
import Marzipano from './MarzipanoViewer';
import Hotspots from './Hotspots';
import './Explorer.css';
import backBtn from '../icon/back.svg';
import backOval from '../icon/back-oval.svg';
import {
  removeAllHotspots,
  stopAutoRotate,
  startAutoRotate,
  changeCamera,
  enable,
  disable,
  mousePositionToMarzipanoCoords,
  position,
  destroyAllHostpots,
  addEventEmissionOnDrag,
  addPulse,
  removePulse,
} from '../libs/marzipano';
import { MarzipanoData, MarzipanoViewOptionalParameters } from '../types/marzipano';
//import MainBar from './MainBar';
import { decodeHash, GlobalVars } from '../libs/utils';
import PointAndAskForm from './PointAndAskForm';
import DropdownCircuits from './DropdownCircuits';
import DecorSelect from './DecorSelect';
import { ReactComponent as FeedbackSVG } from '../icon/feedback.svg';
import Pulse from '../components/pulse';
import ModelViewer from '../components/viewer';
import NewNavBar from '../components/NewNavBar';
import NavigationArrow from '../components/NavigationArrow';
import NewMobileNavBar from '../components/NewMobileNavBar';
import Group from '../components/group';
import GA from '../libs/ga';

export default class Explorer extends React.Component<ExplorerProps> {
  keysPressed: any = [];
  cursorX: number = 0;
  cursorY: number = 0;
  pulseTimeout: any;

  startingSpaceId = () => {
    let startingSpace: string;
    if (this.props.night && this.props.mappingData.start.spaceIdN) {
      startingSpace = this.props.mappingData.start.spaceIdN;
    } else {
      startingSpace = this.props.mappingData.start.spaceId;
    }
    return startingSpace;
  };

  timelapseAutoplay: ReturnType<typeof setInterval> | undefined;
  //TODO the first space shown should be result of settings in mapping.json and not hardcoded
  startSpace = this.props.mappingData.sectors[this.props.mappingData.start.sectorIndex].spaces.find(
    (space) => space.id === this.startingSpaceId(),
  );

  searchParams = new URLSearchParams(window.location.search);

  startSector = this.searchParams.has('sector')
    ? Number(this.searchParams.get('sector'))
    : this.props.mappingData.start.sectorIndex;

  startSpaceId = this.searchParams.has('space')
    ? this.searchParams.get('space')
    : this.startingSpaceId();

  newVidRef = React.createRef<HTMLVideoElement>();

  state: ExplorerState = {
    active: false,
    loaded: false,
    selectedSectorIndex: this.startSector,
    selectedSpaceId: String(this.startSpaceId),
    timelapseIndex: 0,
    type: this.startSpace ? this.startSpace.type : '360',
    panoSequenceIndex: 0,
    hotspotSequenceIndex: undefined,
    hotspotSequenceLabel: 'Explore',
    hotspotSequence: [],
    mode: 'normal',
    autoplayIntervalId: undefined,
    unblockedArrows: true,
    lookToAfterLink: undefined,
    pointAndAskActive: false,
    paHotspots: [],
    defaultEmail: '',
    changedMapping: false,
    pulse: undefined,
    showMarzipano: true,
  };

  keyDown = (event: any) => {
    this.keysPressed[event.key] = true;
    if (this.keysPressed['Alt'] && event.key === 'p') {
      console.log(mousePositionToMarzipanoCoords(this.cursorX, this.cursorY));
    }
  };

  componentDidMount = () => {
    onmousemove = (e) => {
      this.cursorX = e.clientX;
      this.cursorY = e.clientY;
    };
    window.addEventListener('startLive', () => {
      if (!this.props.live && GlobalVars.room !== undefined && GlobalVars.socket) {
        GlobalVars.controller = true;
        GlobalVars.socket.emit('mapping', this.props.mappingUrl);
        GlobalVars.socket.emit('sectorIndex', this.state.selectedSectorIndex);
        GlobalVars.socket.emit('scene', {
          id: this.state.selectedSpaceId,
          type: '360',
        });
        GlobalVars.socket.emit('position', position());
        addEventEmissionOnDrag();
        this.props.setLive(true, () => {
          const changeControllerEvent = new Event('changeController');
          window.dispatchEvent(changeControllerEvent);
          GlobalVars.socket?.emit('startLive', true);
        });
        GlobalVars.socket.emit('changeViewer', this.state.showMarzipano);
      }
    });
    document.removeEventListener('keydown', this.keyDown);
    document.addEventListener('keydown', this.keyDown);
    if (this.props.live && !this.state.active) {
      this.setState({ active: true });
    }
    let code;
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      code = urlParams.get('loc');

      const live = urlParams.get('live');
      if (live || this.props.live) {
        GlobalVars.socket?.on('mapping', (mapping: any) => {
          if (mapping !== this.props.mappingUrl) {
            this.setState({ loaded: false, hotspotSequenceIndex: undefined });
          }
        });
        GlobalVars.socket?.on('scene', (scene: any) => {
          if (scene) {
            this.setState({
              selectedSpaceId: scene.id,
              type: scene.type,
              hotspotSequenceIndex: undefined,
            });
          } else {
            this.setState({
              selectedSpaceId: this.props.mappingData.start.spaceId,
              type: '360',
              hotspotSequenceIndex: undefined,
            });
          }
        });
        GlobalVars.socket?.on('sectorIndex', (sectorIndex: number) => {
          if (sectorIndex) {
            this.setState({ selectedSectorIndex: sectorIndex });
          } else {
            this.setState({ selectedSectorIndex: this.props.mappingData.start.sectorIndex });
          }
        });
        GlobalVars.socket?.on('position', (pos: any) => {
          if (pos && this.state.loaded)
            changeCamera(pos.yaw, pos.pitch, pos.fov, 0, () => {}, false);
        });
        GlobalVars.socket?.on('pointHighlight', (pos: { x: number; y: number }) => {
          if (pos) {
            this.setState({ pulse: pos }, () => {
              this.pulseTimeout && clearTimeout(this.pulseTimeout);
              this.pulseTimeout = setTimeout(() => {
                removePulse();
                this.setState({ pulse: undefined });
              }, 1500);
              addPulse({ yaw: pos.x, pitch: pos.y });
            });
          }
        });
        GlobalVars.socket?.on('changeViewer', (showMarzipano: boolean) => {
          showMarzipano !== null && this.setState({ showMarzipano: showMarzipano });
        });
        this.setState({ active: true });
      }
    }
    if (code) {
      const values = decodeHash(code);
      const sector = this.props.mappingData.sectors.find((sector) => sector.name === values.sector);
      const timelapse = Number(values.timelapse);
      if (sector) {
        const sectorIndex = this.props.mappingData.sectors.indexOf(sector);
        if (timelapse > -1) {
          for (let space of this.props.mappingData.sectors[sectorIndex].spaces) {
            if (Array.isArray(space.id)) {
              if (space.id[timelapse] === values.space) {
                this.handleChangeSector(sectorIndex, space.id);
                this.setState({ timelapseIndex: timelapse });
                break;
              }
            }
          }
        } else {
          this.handleChangeSector(sectorIndex, values.space);
        }
      }
    }
    const appdata: MarzipanoData = this.loadAppdata();
    const sData = appdata.scenes.find((space) => space.id === this.startingSpaceId());
    let name = sData?.labelAnalytics ? sData.labelAnalytics : sData?.name;
    ReactGA.pageview(
      '/' +
        this.props.mappingData.sectors[this.props.mappingData.start.sectorIndex].name +
        '/' +
        name,
    );
    GA.pageView(
      '/' +
        this.props.mappingData.sectors[this.props.mappingData.start.sectorIndex].name +
        '/' +
        name,
      name,
    );
    const panoSequenceIndex = appdata.panoSequence?.indexOf(String(this.startSpaceId));
    if (
      panoSequenceIndex !== undefined &&
      panoSequenceIndex >= 0 &&
      panoSequenceIndex !== this.state.panoSequenceIndex
    ) {
      this.setState({ panoSequenceIndex: panoSequenceIndex });
    } else {
      if (panoSequenceIndex === undefined || panoSequenceIndex < 0) {
        const pSeqIdx = appdata.scenes.findIndex((scene) => scene.id === this.startSpaceId);
        pSeqIdx && this.setState({ panoSequenceIndex: pSeqIdx });
      }
    }
    let scene = appdata.scenes.find((scene) => scene.id === this.startSpaceId);
    if (scene) {
      let hotspotSequence = scene.hotspotSequence;
      if (
        appdata.settings.navBar?.randomHotspotSequence &&
        (!hotspotSequence || hotspotSequence.length === 0)
      ) {
        hotspotSequence = [];
        scene.imgHotspots?.forEach((hp, index) => {
          if (hotspotSequence) {
            const randomIndex = Math.random() * hotspotSequence.length;
            hotspotSequence.splice(randomIndex, 0, `imgHotspots[${index}]`);
          }
        });
        scene.infoHotspots?.forEach((hp, index) => {
          if (hotspotSequence) {
            const randomIndex = Math.random() * hotspotSequence.length;
            hotspotSequence.splice(randomIndex, 0, `infoHotspots[${index}]`);
          }
        });
        scene.modalHotspots?.forEach((hp, index) => {
          if (hotspotSequence) {
            const randomIndex = Math.random() * hotspotSequence.length;
            hotspotSequence.splice(randomIndex, 0, `modalHotspots[${index}]`);
          }
        });
      } else {
        if (!hotspotSequence || hotspotSequence.length === 0) startAutoRotate();
      }
      this.setState({
        hotspotSequence: hotspotSequence,
        hotspotSequenceLabel: appdata.settings.navBar?.defaultSentence,
      });
    }
  };

  componentDidUpdate = (prevProps: ExplorerProps, prevState: ExplorerState) => {
    if (this.newVidRef.current) {
      this.newVidRef.current.onended = () => {
        this.advancePanoSequence();
      };
    }
    if (!prevProps.live && this.props.live) {
      if (GlobalVars.socket) {
        GlobalVars.socket.on('mapping', (mapping: any) => {
          if (mapping !== this.props.mappingUrl)
            this.setState({ loaded: false, hotspotSequenceIndex: undefined });
        });
        GlobalVars.socket.on('scene', (scene: any) => {
          this.setState({
            selectedSpaceId: scene.id,
            type: scene.type,
            hotspotSequenceIndex: undefined,
          });
        });
        GlobalVars.socket.on('sectorIndex', (sectorIndex: number) => {
          this.setState({ selectedSectorIndex: sectorIndex });
        });
        GlobalVars.socket.on('position', (pos: any) => {
          if (pos && this.state.loaded)
            changeCamera(pos.yaw, pos.pitch, pos.fov, 0, () => {}, false);
        });
        GlobalVars.socket.on('pointHighlight', (pos: { x: number; y: number }) => {
          if (pos) {
            this.setState({ pulse: pos }, () => {
              this.pulseTimeout && clearTimeout(this.pulseTimeout);
              this.pulseTimeout = setTimeout(() => {
                removePulse();
                this.setState({ pulse: undefined });
              }, 1500);
              addPulse({ yaw: pos.x, pitch: pos.y });
            });
          }
        });
        GlobalVars.socket.on('changeViewer', (showMarzipano: boolean) => {
          showMarzipano !== null && this.setState({ showMarzipano: showMarzipano });
        });
      }
    }
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    if (GlobalVars.room !== undefined && this.props.live && this.state.loaded) {
      addEventEmissionOnDrag();
    }

    onmousemove = (e) => {
      this.cursorX = e.clientX;
      this.cursorY = e.clientY;
    };
    document.removeEventListener('keydown', this.keyDown);
    document.addEventListener('keydown', this.keyDown);
    const appdata: MarzipanoData = this.loadAppdata();
    let code;
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      code = urlParams.get('loc');
    }
    if (code) {
      const values = decodeHash(code);
      if (this.state.loaded && !this.state.active) {
        this.setState({ active: true }, () => {
          let yaw = Number(values.yaw);
          let pitch = Number(values.pitch);
          let fov = Number(values.fov);
          if (appdata.settings.anglesInDegrees) {
            yaw = (yaw * 180) / Math.PI;
            pitch = (pitch * 180) / Math.PI;
            fov = (fov * 180) / Math.PI;
          }
          changeCamera(yaw, pitch, fov, 0.1, () => {});
        });
      }
    }

    if (prevProps.night !== this.props.night) {
      this.changeBarLabel(appdata.settings.navBar?.defaultSentence);
      this.startSpace = this.props.mappingData.sectors[
        this.props.mappingData.start.sectorIndex
      ].spaces.find((space) => space.id === this.startingSpaceId());

      this.searchParams = new URLSearchParams(window.location.search);

      this.startSector = this.searchParams.has('sector')
        ? Number(this.searchParams.get('sector'))
        : this.props.mappingData.start.sectorIndex;

      this.startSpaceId = this.searchParams.has('space')
        ? this.searchParams.get('space')
        : this.startingSpaceId();

      const panoSequenceIndex = appdata.panoSequence?.indexOf(String(this.startSpaceId));
      if (panoSequenceIndex && panoSequenceIndex !== this.state.panoSequenceIndex) {
        this.setState({ panoSequenceIndex: panoSequenceIndex });
      }

      if (!Array.isArray(this.state.selectedSpaceId)) {
        if (this.state.selectedSpaceId.length < 1) {
          this.setState({ selectedSpaceId: this.startSpaceId });
        }
      }

      this.setState({
        selectedSectorIndex: this.startSector,
      });
    }

    if (prevProps.mappingData !== this.props.mappingData) {
      const sData = appdata.scenes.find((space) => space.id === this.startingSpaceId());
      let name = sData?.labelAnalytics ? sData.labelAnalytics : sData?.name;
      ReactGA.pageview(
        '/' +
          this.props.mappingData.sectors[this.props.mappingData.start.sectorIndex].name +
          '/' +
          name,
      );
      GA.pageView(
        '/' +
          this.props.mappingData.sectors[this.props.mappingData.start.sectorIndex].name +
          '/' +
          name,
        name,
      );
      this.changeBarLabel(appdata.settings.navBar?.defaultSentence);
      this.startSpace = this.props.mappingData.sectors[
        this.props.mappingData.start.sectorIndex
      ].spaces.find((space) => space.id === this.startingSpaceId());

      this.searchParams = new URLSearchParams(window.location.search);

      this.startSector = this.searchParams.has('sector')
        ? Number(this.searchParams.get('sector'))
        : this.props.mappingData.start.sectorIndex;

      this.startSpaceId = this.searchParams.has('space')
        ? this.searchParams.get('space')
        : this.startingSpaceId();

      const panoSequenceIndex = appdata.panoSequence?.indexOf(String(this.startSpaceId));
      if (panoSequenceIndex && panoSequenceIndex !== this.state.panoSequenceIndex) {
        this.setState({ panoSequenceIndex: panoSequenceIndex });
      }

      if (!Array.isArray(this.state.selectedSpaceId)) {
        if (this.state.selectedSpaceId.length < 1) {
          this.setState({ selectedSpaceId: this.startSpaceId });
        }
      }

      this.setState({
        selectedSectorIndex: this.startSector,
      });

      if (mobile) {
        this.setState({ changedMapping: true });
        setTimeout(() => {
          this.setState({ changedMapping: false });
        }, 3000);
      }
    }
    if (prevState.timelapseIndex !== this.state.timelapseIndex) {
      if (this.state.lookToAfterLink) {
        changeCamera(
          this.state.lookToAfterLink.yaw,
          this.state.lookToAfterLink.pitch,
          this.state.lookToAfterLink.fov,
          0.1,
          () => {
            this.setState({ lookToAfterLink: undefined });
          },
        );
      }
    }
    if (this.state.selectedSpaceId !== prevState.selectedSpaceId) {
      this.unblockArrows();
      if (this.state.mode && mobile) {
        switch (this.state.mode) {
          case 'normal':
            if (!this.props.live) enable();
            break;
          default:
            disable();
            break;
        }
      }
      const panoSequenceIndex = appdata.panoSequence?.indexOf(String(this.startSpaceId));
      if (
        panoSequenceIndex !== undefined &&
        panoSequenceIndex >= 0 &&
        panoSequenceIndex !== this.state.panoSequenceIndex
      ) {
        this.setState({ panoSequenceIndex: panoSequenceIndex });
      } else {
        if (panoSequenceIndex === undefined || panoSequenceIndex < 0) {
          const pSeqIdx = appdata.scenes.findIndex((scene) => scene.id === this.startSpaceId);
          pSeqIdx && this.setState({ panoSequenceIndex: pSeqIdx });
        }
      }
      let s = Array.isArray(this.state.selectedSpaceId)
        ? this.state.selectedSpaceId[this.state.timelapseIndex]
        : this.state.selectedSpaceId;
      let scene = appdata.scenes.find((scene) => scene.id === s);
      if (scene) {
        if (this.state.lookToAfterLink) {
          changeCamera(
            this.state.lookToAfterLink.yaw,
            this.state.lookToAfterLink.pitch,
            this.state.lookToAfterLink.fov,
            0.1,
            () => {
              this.setState({ lookToAfterLink: undefined });
              if (this.props.live && GlobalVars.room !== undefined) {
                //GlobalVars.socket?.emit('position', position());
              }
            },
          );
        } else {
          changeCamera(
            scene.initialViewParameters.yaw,
            scene.initialViewParameters.pitch,
            scene.initialViewParameters.fov,
            0.1,
            () => {
              if (this.props.live && GlobalVars.room !== undefined) {
                //GlobalVars.socket?.emit('position', position());
              }
            },
          );
        }
        let hotspotSequence = scene.hotspotSequence;
        if (
          appdata.settings.navBar?.randomHotspotSequence &&
          (!hotspotSequence || hotspotSequence.length === 0)
        ) {
          hotspotSequence = [];
          scene.imgHotspots?.forEach((hp, index) => {
            if (hotspotSequence) {
              const randomIndex = Math.random() * hotspotSequence.length;
              hotspotSequence.splice(randomIndex, 0, `imgHotspots[${index}]`);
            }
          });
          scene.infoHotspots?.forEach((hp, index) => {
            if (hotspotSequence) {
              const randomIndex = Math.random() * hotspotSequence.length;
              hotspotSequence.splice(randomIndex, 0, `infoHotspots[${index}]`);
            }
          });
          scene.modalHotspots?.forEach((hp, index) => {
            if (hotspotSequence) {
              const randomIndex = Math.random() * hotspotSequence.length;
              hotspotSequence.splice(randomIndex, 0, `modalHotspots[${index}]`);
            }
          });
        } else {
          //if (!hotspotSequence || hotspotSequence.length === 0) startAutoRotate();
        }
        this.setState({
          hotspotSequence: hotspotSequence,
          hotspotSequenceLabel: appdata.settings.navBar?.defaultSentence,
        });
      }
    }
    if (this.state.mode !== prevState.mode) {
      switch (this.state.mode) {
        case 'normal':
          startAutoRotate();
          if (prevState.mode === 'autoplay') {
            clearInterval(this.state.autoplayIntervalId);
            this.setState({ autoplayIntervalId: undefined });
          }
          this.setState({
            hotspotSequenceIndex: undefined,
            hotspotSequenceLabel: appdata.settings.navBar?.defaultSentence,
          });
          this.unblockArrows();
          break;
        case 'paused':
          stopAutoRotate();
          if (prevState.mode === 'autoplay') {
            clearInterval(this.state.autoplayIntervalId);
            this.setState({ autoplayIntervalId: undefined });
          }
          /*setTimeout(() => {
            this.autoPlay();
          }, 5000);*/
          break;
        case 'autoplay':
          if (appdata.settings.navBar?.enabled && appdata.settings.navBar.autoplay.enabled) {
            if (appdata.settings.navBar?.randomHotspotSequence) stopAutoRotate();
            else startAutoRotate();
            if (prevState.mode !== 'autoplay') {
              const duration = appdata.settings.navBar.autoplay.intervalDuration
                ? appdata.settings.navBar.autoplay.intervalDuration
                : 1000;
              this.advanceSequence();
              const id = setInterval(() => {
                if (this.state.type !== 'video_new') this.advanceSequence();
              }, duration);
              this.setState({ autoplayIntervalId: id });
            }
          }
          break;
      }
    }
    if (
      this.state.hotspotSequenceIndex === undefined &&
      this.state.hotspotSequenceIndex !== prevState.hotspotSequenceIndex
    ) {
      if (this.state.mode === 'autoplay') {
        this.setState({ hotspotSequenceLabel: appdata.settings.navBar?.defaultSentence });
      } else {
        this.setState({
          mode: 'normal',
          hotspotSequenceLabel: appdata.settings.navBar?.defaultSentence,
        });
      }
    }
  };

  isLive = () => {
    return !this.props.live && GlobalVars.room !== undefined && GlobalVars.socket;
  };

  getAllConfigs = () => {
    return {
      mapping: this.props.mappingUrl,
      sector: this.props.mappingData.sectors[this.state.selectedSectorIndex].name,
      space: this.state.selectedSpaceId,
      timelapse: this.state.timelapseIndex,
      language: this.props.language ? this.props.language : 'pt',
    };
  };

  loadAppdata = () => {
    let appdata: MarzipanoData;
    if (
      this.props.night &&
      this.props.mappingData.sectors[this.state.selectedSectorIndex].data360n
    ) {
      appdata = require(`../JSON/${
        this.props.mappingData.sectors[this.state.selectedSectorIndex].data360n
      }`);
    } else {
      appdata = require(`../JSON/${
        this.props.mappingData.sectors[this.state.selectedSectorIndex].data360
      }`);
    }

    return appdata;
  };

  findName = () => {
    const selScene = Array.isArray(this.state.selectedSpaceId)
      ? this.state.selectedSpaceId[this.state.timelapseIndex]
      : this.state.selectedSpaceId;
    if (this.state.type === 'video_new') {
      let name;
      const appdata = this.loadAppdata();
      appdata.scenes.forEach((scene) => {
        scene.linkHotspots?.forEach((link) => {
          if (link.target === selScene) {
            name = link.name;
          }
        });
      });
      return name;
    }
    let name = this.props.mappingData.sectors[this.state.selectedSectorIndex].spaces.find(
      (space) => space.id === selScene,
    )?.name;

    if (!name) {
      const appdata = this.loadAppdata();
      name = appdata.scenes.find((s) => s.id === selScene)?.name;
    }

    return name;
  };

  handleChangeSpace = (
    spaceId: string | string[],
    type: string | undefined,
    lookTo?: MarzipanoViewOptionalParameters,
  ) => {
    const sceneType = type ? type : '360';
    if (GlobalVars.room !== undefined && this.props.live && GlobalVars.controller) {
      const scene = { id: spaceId, type: sceneType };
      GlobalVars.socket?.emit('scene', scene);
    }
    const scene = Array.isArray(spaceId) ? spaceId[0] : spaceId;
    const appdata: MarzipanoData = this.loadAppdata();
    if (scene) {
      const sData = appdata.scenes.find((space) => space.id === scene);
      let name = sData?.labelAnalytics ? sData.labelAnalytics : sData?.name;
      ReactGA.pageview(
        '/' + this.props.mappingData.sectors[this.state.selectedSectorIndex].name + '/' + name,
      );
      GA.pageView(
        '/' + this.props.mappingData.sectors[this.state.selectedSectorIndex].name + '/' + name,
        name,
      );
    }
    this.setState({ selectedSpaceId: spaceId, type: sceneType, hotspotSequenceIndex: undefined });
    if (lookTo) {
      this.setState({ lookToAfterLink: lookTo });
    }
  };

  handleLinkHotspot = (
    newSceneId: string | string[],
    newMapping: string | null,
    newSector: number | null,
    lookTo: MarzipanoViewOptionalParameters | null,
    newType?: string,
  ) => {
    const closeAllEvent = new CustomEvent('closeAllHotspots', {
      detail: {},
    });
    window.dispatchEvent(closeAllEvent);
    if (newSector !== null && newSector !== undefined) {
      const s = Array.isArray(newSceneId) ? newSceneId[0] : newSceneId;
      this.handleChangeSector(newSector, s);
    } else {
      const s = Array.isArray(newSceneId) ? newSceneId[0] : newSceneId;
      const type = !newType
        ? this.props.mappingData.sectors[this.state.selectedSectorIndex].spaces.find(
            (space) => space.id === s,
          )?.type
        : newType;
      if (newMapping) {
        this.props.changeMapping(newMapping);
        this.handleChangeSpace(newSceneId, type);
        if (lookTo) {
          this.setState({ lookToAfterLink: lookTo });
        }
        this.setState({ loaded: false, hotspotSequenceIndex: undefined });
      } else {
        if (lookTo) {
          this.setState({ lookToAfterLink: lookTo });
        }
        this.handleChangeSpace(newSceneId, type);
      }
    }
  };

  handleNight = (night: boolean) => {
    const lookTo = position();
    destroyAllHostpots();
    this.props.toggleNight(night);
    const appdata = this.loadAppdata();
    const selSpace = Array.isArray(this.state.selectedSpaceId)
      ? this.state.selectedSpaceId[this.state.timelapseIndex]
      : this.state.selectedSpaceId;
    const spaceIndex = appdata.scenes
      .map((s) => {
        return s.id;
      })
      .indexOf(selSpace);
    if (night) {
      const nextAppdata: MarzipanoData = require(`../JSON/${
        this.props.mappingData.sectors[this.state.selectedSectorIndex].data360n
      }`);
      const nextSpaceId = nextAppdata.scenes[spaceIndex].id;
      this.handleChangeSpace(nextSpaceId, undefined, lookTo);
    } else {
      const nextAppdata: MarzipanoData = require(`../JSON/${
        this.props.mappingData.sectors[this.state.selectedSectorIndex].data360
      }`);
      const nextSpaceId = nextAppdata.scenes[spaceIndex].id;
      this.handleChangeSpace(nextSpaceId, undefined, lookTo);
    }
    this.setState({ loaded: false, hotspotSequenceIndex: undefined });
  };

  backFunction = () => {
    destroyAllHostpots();
    if (this.props.return) {
      if (this.state.type !== '360') {
        this.props.return();
        this.setState({
          selectedSectorIndex: this.startSector,
          selectedSpaceId: '',
          type: '360',
          loaded: false,
          hotspotSequenceIndex: undefined,
        });
      } else {
        this.props.return();
        this.setState({
          selectedSectorIndex: this.startSector,
          selectedSpaceId: '',
          loaded: false,
          hotspotSequenceIndex: undefined,
        });
      }
    }
  };

  handleChangeSector = (sectorIndex: number, sectorStart: string | string[]) => {
    removeAllHotspots();
    if (sectorStart) {
      const appdata: MarzipanoData = this.loadAppdata();
      const sData = appdata.scenes.find((space) => space.id === this.startingSpaceId());
      let name = sData?.labelAnalytics ? sData.labelAnalytics : sData?.name;
      ReactGA.pageview('/' + this.props.mappingData.sectors[sectorIndex].name + '/' + name);
      GA.pageView('/' + this.props.mappingData.sectors[sectorIndex].name + '/' + name, name);
      this.setState({
        selectedSectorIndex: sectorIndex,
        selectedSpaceId: sectorStart,
        loaded: false,
        hotspotSequenceIndex: undefined,
      });
    }
  };

  handleViewer3D = async (
    newSceneId: string,
    newSector: number | null,
    lookTo: MarzipanoViewOptionalParameters,
  ) => {
    if (
      this.props.live &&
      GlobalVars.room !== undefined &&
      GlobalVars.socket &&
      GlobalVars.controller
    ) {
      GlobalVars.socket.emit('changeViewer', true);
    }
    const callback = () => this.setState({ showMarzipano: true, switchSceneCallback: undefined });
    this.setState({ switchSceneCallback: callback }, () => {
      this.handleLinkHotspot(newSceneId, null, newSector, lookTo);
    });
  };

  load = () => {
    this.setState({ loaded: true });
  };

  unblockArrows = () => {
    this.setState({ unblockedArrows: true });
  };

  advanceSequence = () => {
    if (this.state.unblockedArrows) {
      if (this.state.mode !== 'autoplay') this.setState({ unblockedArrows: false });
      const closeAllEvent = new CustomEvent('closeAllHotspots', {
        detail: {},
      });
      window.dispatchEvent(closeAllEvent);
      if (this.state.hotspotSequence && this.state.hotspotSequence.length > 0) {
        if (this.state.hotspotSequenceIndex === this.state.hotspotSequence.length - 1) {
          this.advancePanoSequence();
        } else {
          this.advanceHotspotSequence();
        }
      } else {
        this.advancePanoSequence();
      }
    }
  };

  returnSequence = () => {
    if (this.state.unblockedArrows) {
      this.setState({ unblockedArrows: false });
      const closeAllEvent = new CustomEvent('closeAllHotspots', {
        detail: {},
      });
      window.dispatchEvent(closeAllEvent);
      if (this.state.hotspotSequence && this.state.hotspotSequence.length > 0) {
        if (this.state.hotspotSequenceIndex === 0) {
          this.returnPanoSequence();
        } else {
          this.returnHotspotSequence();
        }
      } else {
        this.returnPanoSequence();
      }
    }
  };

  advancePanoSequence = () => {
    const appdata: MarzipanoData = this.loadAppdata();
    let panoSequence = appdata.panoSequence;
    if (!panoSequence || panoSequence.length === 0) {
      panoSequence = [];
      appdata.scenes.forEach((scene) => {
        panoSequence?.push(scene.id);
      });
    }
    if (
      Array.isArray(this.state.selectedSpaceId) &&
      this.state.timelapseIndex < this.state.selectedSpaceId.length - 1
    ) {
      this.nextTimelapse();
    } else {
      if (panoSequence) {
        const id = !Array.isArray(this.state.selectedSpaceId)
          ? this.state.selectedSpaceId
          : this.state.selectedSpaceId[0];
        const tmp = panoSequence.slice(this.state.panoSequenceIndex);
        let currentIndex = tmp.findIndex((s) => s === id);
        currentIndex = currentIndex < 0 ? panoSequence.findIndex((s) => s === id) : currentIndex;
        currentIndex += panoSequence.length - tmp.length;
        currentIndex = currentIndex < 0 ? 0 : currentIndex;
        let next: number;
        if (currentIndex + 1 === panoSequence.length) {
          clearInterval(this.state.autoplayIntervalId);
          this.setState({ autoplayIntervalId: undefined }, () => {
            this.props.changeToForm();
          });
          next = 0;
        } else {
          next = currentIndex + 1;
        }
        const nextScene = panoSequence[next];
        const existScene = appdata.scenes.filter((s) => s.id === nextScene).length > 0;
        const type = existScene
          ? this.props.mappingData.sectors[this.state.selectedSectorIndex].spaces.find(
              (space) => space.id === nextScene,
            )?.type
          : 'video_new';
        this.handleChangeSpace(panoSequence[next], type);
        this.setState({
          panoSequenceIndex: next,
          hotspotSequenceIndex: undefined,
          unblockedArrows: true,
        });
      }
    }
  };

  returnPanoSequence = () => {
    const appdata: MarzipanoData = this.loadAppdata();
    let panoSequence = appdata.panoSequence;
    if (!panoSequence || panoSequence.length === 0) {
      panoSequence = [];
      appdata.scenes.forEach((scene) => {
        panoSequence?.push(scene.id);
      });
    }
    if (Array.isArray(this.state.selectedSpaceId) && this.state.timelapseIndex > 0) {
      this.previousTimelapse();
    } else {
      if (panoSequence) {
        const id = !Array.isArray(this.state.selectedSpaceId)
          ? this.state.selectedSpaceId
          : this.state.selectedSpaceId[0];
        const tmp = panoSequence.slice(this.state.panoSequenceIndex);
        let currentIndex = tmp.findIndex((s) => s === id);
        currentIndex = currentIndex < 0 ? panoSequence.findIndex((s) => s === id) : currentIndex;
        currentIndex += panoSequence.length - tmp.length;
        currentIndex = currentIndex < 0 ? 0 : currentIndex;
        let next: number;
        if (currentIndex === 0) {
          this.backFunction();
          next = panoSequence.length - 1;
        } else {
          next = currentIndex - 1;
        }
        const nextScene = panoSequence[next];
        const existScene = appdata.scenes.filter((s) => s.id === nextScene).length > 0;
        const type = existScene
          ? this.props.mappingData.sectors[this.state.selectedSectorIndex].spaces.find(
              (space) => space.id === nextScene,
            )?.type
          : 'video_new';
        this.handleChangeSpace(panoSequence[next], type);
        this.setState({
          panoSequenceIndex: next,
          hotspotSequenceIndex: undefined,
          unblockedArrows: true,
        });
      }
    }
  };

  advanceHotspotSequence = () => {
    if (this.state.hotspotSequence) {
      let next: number;
      if (this.state.hotspotSequenceIndex !== undefined) {
        next = this.state.hotspotSequenceIndex + 1;
      } else {
        next = 0;
      }
      this.setState({ hotspotSequenceIndex: next });
    }
  };

  returnHotspotSequence = () => {
    if (this.state.hotspotSequence) {
      let next: number;
      if (this.state.hotspotSequenceIndex !== undefined) {
        next = this.state.hotspotSequenceIndex - 1;
      } else {
        next = this.state.hotspotSequence.length - 1;
      }
      this.setState({ hotspotSequenceIndex: next });
    }
  };

  autoPlay = () => {
    if (this.state.mode === 'autoplay') {
      this.setState({ mode: 'paused' });
    } else {
      this.setState({ mode: 'autoplay' });
    }
  };

  changeBarLabel = (label: string | undefined) => {
    const appdata: MarzipanoData = this.loadAppdata();
    const lbl = label ? label : appdata.settings.navBar?.defaultSentence;
    this.setState({ hotspotSequenceLabel: lbl });
  };

  changeMode = (newMode: string) => {
    this.setState({ mode: newMode });
  };

  nextTimelapse = () => {
    if (Array.isArray(this.state.selectedSpaceId)) {
      const closeAllEvent = new CustomEvent('closeAllHotspots', {
        detail: {},
      });
      window.dispatchEvent(closeAllEvent);
      const pos = position();
      const next =
        this.state.timelapseIndex === this.state.selectedSpaceId.length - 1
          ? 0
          : this.state.timelapseIndex + 1;
      this.setState({ timelapseIndex: next, lookToAfterLink: pos });
    }
  };

  previousTimelapse = () => {
    if (Array.isArray(this.state.selectedSpaceId)) {
      const closeAllEvent = new CustomEvent('closeAllHotspots', {
        detail: {},
      });
      window.dispatchEvent(closeAllEvent);
      const pos = position();
      const next =
        this.state.timelapseIndex === 0
          ? this.state.selectedSpaceId.length - 1
          : this.state.timelapseIndex - 1;
      this.setState({ timelapseIndex: next, lookToAfterLink: pos });
    }
  };

  selectTimelapse = (timelapseID: number) => {
    if (Array.isArray(this.state.selectedSpaceId)) {
      const closeAllEvent = new CustomEvent('closeAllHotspots', {
        detail: {},
      });
      const pos = position();
      window.dispatchEvent(closeAllEvent);
      this.setState({ timelapseIndex: timelapseID, lookToAfterLink: pos });
    }
  };

  autoplayTimelapse = () => {
    if (!this.timelapseAutoplay) {
      if (Array.isArray(this.state.selectedSpaceId)) {
        this.nextTimelapse();
        this.timelapseAutoplay = setInterval(() => {
          this.nextTimelapse();
        }, 5000);
      }
    } else {
      clearInterval(this.timelapseAutoplay);
      this.timelapseAutoplay = undefined;
    }
  };

  togglePointAndAsk = (nextState: boolean) => {
    if (nextState) {
      destroyAllHostpots();
    }
    this.changeMode('normal');
    this.setState({ pointAndAskActive: nextState });
  };

  addPAHotspot = (pos: MarzipanoViewOptionalParameters, question: string) => {
    const pa = {
      scene: Array.isArray(this.state.selectedSpaceId)
        ? this.state.selectedSpaceId[this.state.timelapseIndex]
        : this.state.selectedSpaceId,
      pos: pos,
      question: question,
    };

    const tmp = this.state.paHotspots.slice(0);
    tmp.push(pa);

    this.setState({ paHotspots: tmp });
  };

  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    let Map;
    //let mapEnabled = false;
    const mapConfig = this.props.mappingData.sectors[this.state.selectedSectorIndex].map;
    if (mapConfig) {
      if (
        this.props.mappingData.sectors[this.state.selectedSectorIndex].spaces.find(
          (space) => space.id === this.state.selectedSpaceId,
        )?.showMap !== false
      ) {
        Map = require(`../templates/maps/${mapConfig.template}.template.tsx`).default;
        //mapEnabled = true;
      }
      //Map = require(`../templates/maps/${mapConfig.template}.template.tsx`).default;
      //mapEnabled = true;
    }

    let viewer = [];
    let data: MarzipanoData | undefined;
    if (this.state.type === '360' || this.state.type === '360_compare') {
      const appdata = this.loadAppdata();
      data = appdata;
      viewer.push(
        <Marzipano
          key={'marzKey'}
          appdata={appdata}
          active={this.state.active}
          selectedScene={this.state.selectedSpaceId}
          timelapseIndex={this.state.timelapseIndex}
          type={this.state.type}
          loaded={this.state.loaded}
          load={this.load}
          mode={this.state.mode}
          changeMode={this.changeMode}
          activate={() => this.setState({ active: true })}
          live={this.props.live ? true : false}
          show={this.state.showMarzipano}
          switchSceneCallback={this.state.switchSceneCallback}
        />,
      );
      if (this.state.active && this.state.loaded) {
        viewer.push(
          <Hotspots
            key={'hKey'}
            appdata={appdata}
            language={this.props.language}
            linkClick={this.handleLinkHotspot}
            hotspotSequenceIndex={this.state.hotspotSequenceIndex}
            hotspotSequence={this.state.hotspotSequence}
            selectedSpaceId={this.state.selectedSpaceId}
            timelapseIndex={this.state.timelapseIndex}
            mode={this.state.mode}
            changeMode={this.changeMode}
            changeBarLabel={this.changeBarLabel}
            unblockArrows={this.unblockArrows}
            pointAndAsk={this.state.pointAndAskActive}
            paHotspots={this.state.paHotspots}
            live={this.props.live}
            night={this.props.night}
            changeToForm={this.props.changeToForm}
          />,
        );
      }
      if (appdata.settings.model3D) {
        viewer.push(
          <ModelViewer
            key="3dKey"
            selectedSpaceId={this.state.selectedSpaceId}
            onDoubleClick={this.handleViewer3D}
            activate={() => this.setState({ active: true })}
            viewerMappingPath={appdata.settings.model3D}
            show={this.state.showMarzipano}
            live={this.props.live}
          />,
        );
      }
    }
    if (this.state.type === 'video_new') {
      const appdata = this.loadAppdata();
      data = appdata;
      const videoSrc = !Array.isArray(this.state.selectedSpaceId) ? this.state.selectedSpaceId : '';
      viewer.push(
        <video
          ref={this.newVidRef}
          key="video_new"
          className="video_new"
          autoPlay
          preload="auto"
          playsInline={true}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>,
      );
    }
    if (this.state.type === 'video') {
      const videoSrc = this.props.mappingData.sectors[this.state.selectedSectorIndex].spaces.find(
        (space) => space.id === this.state.selectedSpaceId,
      )?.src;
      if (videoSrc) {
        viewer.push(
          <iframe
            key={'youtubeKey'}
            title="Video"
            style={{ position: 'absolute', width: '100%', height: '100vh' }}
            src={`${videoSrc}?controls=0&autoplay=1&autorepeat=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>,
        );
      }
    }
    if (this.state.type === 'video_old') {
      viewer.push(
        <iframe
          key={'youtubeKey'}
          title="Video"
          style={{ position: 'absolute', width: '100%', height: '100%' }}
          src={`${this.state.selectedSpaceId}?controls=0&autoplay=1&autorepeat=1`}
          frameBorder="0"
          allowFullScreen
        ></iframe>,
      );
    }

    /*const progress =
      this.state.hotspotSequenceIndex !== undefined
        ? ((this.state.hotspotSequenceIndex + 1) / this.state.hotspotSequence.length) * 100
        : 0;*/

    let panoSeq: string[] | undefined;
    let panoSequence: string[] = [];
    let nameOnTopContent = null;
    //let panoInfo = undefined;
    if (data) {
      panoSeq = data.panoSequence;
      if (!panoSeq || panoSeq.length === 0) {
        panoSeq = [];
        data.scenes.forEach((scene) => {
          panoSeq?.push(scene.id);
        });
      }
      if (panoSeq) {
        panoSequence = panoSeq;
      }
      if (data.settings.nameOnTop?.enabled && this.state.active && !mobile) {
        const sceneData = data.scenes.find((scene) => scene.id === this.state.selectedSpaceId);
        //const name = this.findName();
        const name = sceneData ? sceneData.name : this.findName();
        const subname = sceneData?.subname
          ? sceneData.subname
          : data.settings.nameOnTop.globalSubname;
        nameOnTopContent = (
          <div className="topNameContainer">
            <h1>{name}</h1>
            <h2>{subname}</h2>
            {this.props.dropdownCircuits ? (
              <DropdownCircuits
                currentMapping={this.props.mappingUrl}
                circuitsList={this.props.circuits ? this.props.circuits : []}
                changeCircuit={this.handleLinkHotspot}
                night={this.props.night}
              />
            ) : null}
          </div>
        );
      }

      const sceneData = data.scenes.find((scene) => scene.id === this.state.selectedSpaceId);
      if (sceneData?.information) {
        //panoInfo = { title: sceneData.name, description: sceneData.information };
      }
    }

    const coords =
      this.props.night && this.props.mappingData.sectors[this.state.selectedSectorIndex].spacesN
        ? this.props.mappingData.sectors[this.state.selectedSectorIndex].spacesN?.find(
            (scene) => scene.id === this.state.selectedSpaceId,
          )?.coords
        : this.props.mappingData.sectors[this.state.selectedSectorIndex].spaces.find(
            (scene) => scene.id === this.state.selectedSpaceId,
          )?.coords;

    const title = this.findName();

    const spacesData =
      this.props.night && this.props.mappingData.sectors[this.state.selectedSectorIndex].spacesN
        ? this.props.mappingData.sectors[this.state.selectedSectorIndex].spacesN
        : this.props.mappingData.sectors[this.state.selectedSectorIndex].spaces;

    const paactive = this.state.pointAndAskActive ? ' pa' : '';

    /*const showNightButton =
      data?.scenes.find((scene) => scene.id === this.state.selectedSpaceId)?.night !== false*/

    const decorDataPath =
      this.props.night && this.props.mappingData.sectors[this.state.selectedSectorIndex].decorationN
        ? this.props.mappingData.sectors[this.state.selectedSectorIndex].decorationN
        : this.props.mappingData.sectors[this.state.selectedSectorIndex].decoration;

    //const appdata = this.loadAppdata();

    const sceneData = data?.scenes.find((scene) => scene.id === this.state.selectedSpaceId);

    let thumbnailsList: any[] = [];
    if (data) {
      thumbnailsList = panoSequence.map((t) => {
        const existScene = data ? data.scenes.filter((s) => s.id === t).length > 0 : false;
        if (!existScene) {
          return undefined;
        }
        const scene = data?.scenes.find((scene) => scene.id === t);
        return {
          id: scene?.id,
          label: scene?.name,
          src: `./assets/thumbnails/${t}.png`,
          selected: t === this.state.selectedSpaceId,
        };
      });
      thumbnailsList = thumbnailsList.filter((t) => t !== undefined);
    }

    const sceneName = this.findName();

    const humanization = new URLSearchParams(window.location.search).get('humanization') === 'true';

    return (
      <div className={'explorer-container' + paactive}>
        <Overlay
          active={this.state.active}
          changeLanguage={this.props.changeLanguage}
          languages={this.props.languages}
          language={this.props.language}
          aliveVisionSVG={this.props.aliveVision ? this.props.aliveVision : 'normal'}
          showPartners={this.props.showPartners !== false ? true : false}
        />
        <Logo
          logo={this.props.logo}
          back={backBtn}
          background={backOval}
          active={this.state.active && !this.state.pointAndAskActive}
          backFunction={this.backFunction}
        />
        {nameOnTopContent}
        {mobile && this.state.changedMapping ? (
          <div className="feedback-container">
            <FeedbackSVG />
            <p>
              Entrou no circuito{' '}
              {this.props.mappingData.sectors[this.state.selectedSectorIndex].name}
            </p>
          </div>
        ) : null}
        {Map && !this.state.pointAndAskActive ? (
          <Map
            selected={{
              space: this.state.selectedSpaceId,
              sectorIndex: this.state.selectedSectorIndex,
            }}
            sectorsData={this.props.mappingData.sectors}
            click={this.handleChangeSpace}
            sectorClick={this.handleChangeSector}
            active={this.state.active}
            coords={coords}
            title={title}
            spacesData={spacesData}
            language={this.props.language}
          />
        ) : null}
        {decorDataPath && this.state.active && !this.state.pointAndAskActive && !mobile ? (
          <DecorSelect
            decorDataPath={decorDataPath}
            goToDecor={this.handleLinkHotspot}
            currentMappingPath={this.props.mappingUrl}
          />
        ) : null}
        {/*mobile &&
        data &&
        this.state.active &&
        ((GlobalVars.socket && GlobalVars.controller && this.props.live) || !this.props.live) ? (
          <MainBar
            appdata={data}
            language={this.props.language}
            selectedSceneId={this.state.selectedSpaceId}
            currentMapping={this.props.mappingUrl}
            currentSectorIdx={this.state.selectedSectorIndex}
            timelapseIndex={this.state.timelapseIndex}
            sceneName={this.findName()}
            mappingName={this.props.mappingData.sectors[this.state.selectedSectorIndex].name}
            label={this.state.hotspotSequenceLabel}
            rightArrowClick={this.advanceSequence}
            leftArrowClick={this.returnSequence}
            playClick={this.autoPlay}
            mode={this.state.mode}
            changeMode={this.changeMode}
            progress={progress}
            panoSequence={panoSequence}
            changeScene={this.handleChangeSpace}
            navBarSettings={data.settings.navBar}
            mapEnabled={mapEnabled}
            panoInfo={panoInfo}
            getAllConfigs={this.getAllConfigs}
            shareLinkData={{
              mapping: this.props.mappingUrl,
              sector: this.props.mappingData.sectors[this.state.selectedSectorIndex].name,
            }}
            timelapseData={{
              rightArrowClick: this.nextTimelapse,
              leftArrowClick: this.previousTimelapse,
              select: this.selectTimelapse,
              autoplay: this.autoplayTimelapse,
            }}
            pointAndAskData={{
              toggle: this.togglePointAndAsk,
              active: this.state.pointAndAskActive,
            }}
            dayNightData={{
              toggleNight: this.handleNight,
              night: showNightButton ? this.props.night : undefined,
            }}
            circuitsProps={
              this.props.dropdownCircuits
                ? {
                    currentMapping: this.props.mappingUrl,
                    circuitsList: this.props.circuits ? this.props.circuits : [],
                    changeCircuit: this.handleLinkHotspot,
                    night: this.props.night,
                  }
                : undefined
            }
            decorSelectData={{
              decorDataPath: decorDataPath ? decorDataPath : '',
              goToDecor: this.handleLinkHotspot,
              currentMappingPath: this.props.mappingUrl,
            }}
            liveModeData={{ live: this.props.live ? true : false, setLive: this.props.setLive }}
            modelViewerData={{
              handleDollHouse: () => {
                if (
                  this.props.live &&
                  GlobalVars.room !== undefined &&
                  GlobalVars.socket &&
                  GlobalVars.controller
                ) {
                  GlobalVars.socket.emit('changeViewer', !this.state.showMarzipano);
                }
                this.setState({ showMarzipano: !this.state.showMarzipano });
              },
              showMarzipano: this.state.showMarzipano,
              enabled: appdata.settings.model3D ? true : false,
            }}
          />
          ) : null*/}
        {mobile && data && this.state.active && (
          <NewMobileNavBar
            nextScene={this.advancePanoSequence}
            prevScene={this.returnPanoSequence}
            description={
              sceneData ? (sceneData.information ? sceneData.information.description : '') : ''
            }
            mode={this.state.mode}
            thumbnails={thumbnailsList}
            changeScene={this.handleChangeSpace}
            playClick={this.autoPlay}
            sceneName={sceneName ? sceneName : ''}
          />
        )}
        {!mobile && data && this.state.active && (
          <NewNavBar
            description={
              sceneData ? (sceneData.information ? sceneData.information.description : '') : ''
            }
            mode={this.state.mode}
            thumbnails={thumbnailsList}
            changeScene={this.handleChangeSpace}
            playClick={this.autoPlay}
            language={this.props.language}
          />
        )}
        {!mobile && this.state.active && humanization && !this.isLive() && <Group />}
        {this.state.active && !mobile && (
          <NavigationArrow
            nextScene={this.advancePanoSequence}
            prevScene={this.returnPanoSequence}
          />
        )}
        {viewer}
        {this.state.pointAndAskActive && data?.settings.navBar?.pointAndAsk ? (
          <PointAndAskForm
            title={data.settings.navBar.pointAndAsk.title}
            clientEmail={data.settings.navBar.pointAndAsk.clientEmail}
            success={data.settings.navBar.pointAndAsk.success}
            selectedSceneId={this.state.selectedSpaceId}
            timelapseIndex={this.state.timelapseIndex}
            mappingFile={this.props.mappingUrl}
            sector={this.props.mappingData.sectors[this.state.selectedSectorIndex].name}
            addHotspot={this.addPAHotspot}
            toggle={this.togglePointAndAsk}
            defaultEmail={this.state.defaultEmail}
            changeDefaultEmail={(email: string) => {
              this.setState({ defaultEmail: email });
            }}
            language={this.props.language}
            flat={
              data.scenes.find((scene) => scene.id === this.state.selectedSpaceId)?.flat
                ? true
                : false
            }
          />
        ) : null}
        {this.state.pulse && (
          <Pulse
            stop={() => {
              this.setState({ state: this.state }, () => {
                removePulse();
                this.setState({ pulse: undefined });
              });
            }}
          />
        )}
      </div>
    );
  }
}
