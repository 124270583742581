import React from 'react';
import Explorer from './Explorer';
import { removeAllHotspots } from '../libs/marzipano';
import { Circuit, LanguagesLandingPage } from '../types/landingPage';
import rotateIcon from '../icon/rotate.svg';
import { GlobalVars } from '../libs/utils';

interface ExplorerContainerProps {
  startingMapping: string | null;
  backFunction: CallableFunction;
  logo: string;
  changeLanguage: CallableFunction | undefined;
  languages: LanguagesLandingPage[] | undefined;
  language: string | undefined;
  aliveVision?: string;
  showPartners?: boolean;
  orientation: number | string;
  webTitle: string;
  night?: boolean;
  toggleNight: CallableFunction;
  dropdownCircuits?: boolean;
  circuits?: Circuit[];
  enableLiveMode?: boolean;
  live?: boolean;
  setLive: CallableFunction;
  changeToForm: CallableFunction;
}

interface ExplorerContainerState {
  mapping: string | null;
}

export default class ExplorerContainer extends React.Component<ExplorerContainerProps> {
  state: ExplorerContainerState = {
    mapping: this.props.startingMapping,
  };

  componentDidMount = () => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);

      const live = urlParams.get('live');
      if (live) {
        GlobalVars.socket?.on('mapping', (mapping: any) => {
          if (mapping && mapping !== this.state.mapping) {
            removeAllHotspots();
            this.setState({ mapping: mapping });
          }
        });
      }
    }
  };

  componentDidUpdate = (prevProps: ExplorerContainerProps) => {
    if (!prevProps.live && this.props.live) {
      GlobalVars.socket?.on('mapping', (mapping: any) => {
        if (mapping && mapping !== this.state.mapping) {
          removeAllHotspots();
          this.setState({ mapping: mapping });
        }
      });
    }
    if (prevProps.startingMapping !== this.props.startingMapping) {
      removeAllHotspots();
    }
    if (prevProps.startingMapping !== this.props.startingMapping) {
      this.setState({ mapping: this.props.startingMapping });
    }
  };

  changeMapping = (newMapping: string) => {
    if (GlobalVars.room !== undefined && this.props.live && GlobalVars.controller) {
      GlobalVars.socket?.emit('mapping', newMapping);
    }
    removeAllHotspots();
    this.setState({ mapping: newMapping });
  };

  handleNight = (night: boolean) => {
    removeAllHotspots();
    this.props.toggleNight(night);
  };

  return = () => {
    /*if (this.state.mapping === this.props.startingMapping) {
      this.props.backFunction();
    } else {
      removeAllHotspots();
      this.setState({ mapping: this.props.startingMapping });
    }*/
    this.props.backFunction();
  };

  render() {
    if (!this.props.startingMapping) throw new Error("Starting Mapping JSON doesn't exist");
    const mappingData = require(`../JSON/${this.state.mapping}`);

    const mappingUrl = this.state.mapping ? this.state.mapping : this.props.startingMapping;

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let rotatePage;

    if (this.props.orientation === 0 && mobile) {
      let mobileRotate = 'Gire o seu dispositivo para ver a melhor experiência';
      switch (this.props.language) {
        case 'English':
          mobileRotate = 'Rotate your device for the best experience';
          break;

        case 'German':
          mobileRotate = 'Rotate your device for the best experience';
          break;

        default:
          mobileRotate = 'Gire o seu dispositivo para ver a melhor experiência';
          break;
      }
      rotatePage = (
        <div id="rotatePage">
          <div className="rotateDevice">
            <img width="20%" height="auto" src={rotateIcon} alt=""></img>
            <p>{mobileRotate}</p>
          </div>
        </div>
      );
    }
    return (
      <>
        {rotatePage}
        <Explorer
          mappingUrl={mappingUrl}
          mappingData={mappingData}
          changeMapping={this.changeMapping}
          return={this.return}
          logo={this.props.logo}
          changeLanguage={this.props.changeLanguage}
          languages={this.props.languages}
          language={this.props.language}
          aliveVision={this.props.aliveVision}
          showPartners={this.props.showPartners}
          toggleNight={this.handleNight}
          night={this.props.night}
          dropdownCircuits={this.props.dropdownCircuits}
          circuits={this.props.circuits}
          enableLiveMode={this.props.enableLiveMode}
          live={this.props.live}
          setLive={this.props.setLive}
          changeToForm={this.props.changeToForm}
        />
      </>
    );
  }
}
