import React, { FunctionComponent } from 'react';
import CSS from 'csstype';
import './CustomLinkHotspot.css';
import { ReactComponent as NextSpaceSVG } from '../icon/type=next-space-up.svg';

interface CustomLinkHotspotProps {
  className: string;
  style: CSS.Properties;
  onClick: CallableFunction;
  onContextMenu: CallableFunction;
  icon: string;
}

const CustomLinkHotspot: FunctionComponent<CustomLinkHotspotProps> = ({
  className,
  style,
  onClick,
  onContextMenu,
  icon,
}) => {
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  const svgStyle = mobile ? { width: style.width, height: style.height } : {};
  const iStyle = mobile ? { width: '14px', height: '14px', fontSize: '14px' } : {};

  return (
    <div
      style={{ ...style }}
      className={'custom-link-hotspot ' + className}
      onClick={() => onClick()}
      onContextMenu={() => onContextMenu()}
    >
      <NextSpaceSVG style={svgStyle} />
      <i className="material-icons" style={iStyle}>
        {icon}
      </i>
    </div>
  );
};

export default CustomLinkHotspot;
