import React, { FunctionComponent, useRef, useState } from 'react';
import './formPage.css';
import { ReactComponent as ForwardSVG } from '../icon/arrow_forward.svg';
import { ReactComponent as AliveVisionSVG } from '../svg/alive_vision_by_3decide_whiteLogomarca.svg';
import emailjs from '@emailjs/browser';
import formLanguage from '../languages/form.json';
import GA from '../libs/ga';

interface FormPageProps {
  imgSrc: string;
  logoSrc: string;
  title: string;
  description: string;
  click: CallableFunction;
  url: string;
  clientEmail: string;
  language: string | undefined;
}

const FormPage: FunctionComponent<FormPageProps> = ({
  imgSrc,
  logoSrc,
  title,
  description,
  click,
  url,
  clientEmail,
  language,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const locRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const [sentMessage, SetSentMessage] = useState<boolean>(false);

  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  const handleSubmit = (evt: any) => {
    evt && evt.preventDefault();
    /*const message =
      messageRef.current && locRef.current
        ? messageRef.current.value + 'Localização:' + locRef.current.value
        : '';*/

    /*{
          to: clientEmail,
          from: {
            email: "miguel.castro@3decide.com",
            name: emailRef.current.value,
          },
          replyTo: emailRef.current.value,
          subject: `Alive Vision Message from: ${nameRef.current.value}`,
          templateId: 'd-71a25f50710f47c79c88c125df04630c',
          dynamicTemplateData: {
            fname: nameRef.current.value,
            floc: locRef.current.value,
            fmessage: messageRef.current.value,
          },
        }*/

    locRef.current &&
      nameRef.current &&
      emailRef.current &&
      messageRef.current &&
      emailjs
        .send(
          'sendgrid_service',
          'petmaxi_template',
          {
            fname: nameRef.current.value,
            femail: emailRef.current.value,
            fmessage: messageRef.current.value,
            client: clientEmail,
          },
          'user_sANYrbsqcz2abHc1Sgjo8',
        )
        .then((response) => {
          if (response.status === 200) {
            if (nameRef.current) nameRef.current.value = '';
            if (emailRef.current) emailRef.current.value = '';
            if (locRef.current) locRef.current.value = '';
            if (messageRef.current) messageRef.current.value = '';
            SetSentMessage(true);
            GA.sendEvent(`submit_form`, {});
            setTimeout(() => {
              SetSentMessage(false);
            }, 1500);
          }
        });
    //send(emailRef.current.value, nameRef.current.value, message);
  };

  /*const send = (from: string, sender: string, message: string) => {
    fetch('http://sendmail.monade.tech/sendmail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: clientEmail,
        email: from,
        name: sender,
        subject: 'Visita 360',
        message: message,
        tid: 'd-d88efab6754940efa00092f08033e655',
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Something went wrong on api server!');
        }
      })
      .then((response) => {
        if (response.status) {
          if (nameRef.current) nameRef.current.value = '';
          if (emailRef.current) emailRef.current.value = '';
          if (locRef.current) locRef.current.value = '';
          if (messageRef.current) messageRef.current.value = '';
          SetSentMessage(true);
          setTimeout(() => {
            SetSentMessage(false);
          }, 1500);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };*/

  let lang = formLanguage.en;
  if (language) {
    switch (language) {
      case 'English':
        lang = formLanguage.en;
        break;

      case 'German':
        lang = formLanguage.de;
        break;

      default:
        lang = formLanguage.pt;
        break;
    }
  }

  if (mobile) {
    return (
      <div className="mobile-form-page">
        <img className="mobile-fp-background" src={imgSrc} alt=""></img>
        <div className="mobile-fp-focus"></div>
        <img className="mobile-fp-logo" src={logoSrc} alt=""></img>
        <div className="mobile-fp-description">{description}</div>
        <div className="mobile-fp-thanks">{lang.thanks}</div>
        <form ref={formRef} onSubmit={handleSubmit}>
          <input
            ref={nameRef}
            className="mobile-fp-input"
            type="text"
            name="fname"
            placeholder={lang.name}
            onBlur={() => {
              GA.sendEvent(`fill_name`, {});
            }}
            required
          ></input>
          <input
            ref={emailRef}
            className="mobile-fp-input"
            type="text"
            name="femail"
            placeholder={lang.email}
            onBlur={() => {
              GA.sendEvent(`fill_email`, {});
            }}
            required
          ></input>
          <input
            ref={locRef}
            className="mobile-fp-input"
            type="text"
            name="floc"
            placeholder={lang.country}
            onBlur={() => {
              GA.sendEvent(`fill_location`, {});
            }}
            required
          ></input>
          <textarea
            ref={messageRef}
            className="mobile-fp-textarea"
            name="fmessage"
            placeholder={lang.writemessage}
            onBlur={() => {
              GA.sendEvent(`fill_message`, {});
            }}
            required
          ></textarea>
          <div className="mobile-fp-send-container">
            <div
              className="mobile-fp-contact"
              onClick={() => {
                GA.sendEvent(`contact_click`, {});
                window.open(`mailto:${clientEmail}`);
              }}
            >
              {lang.contact}
              {'\n'}
              {clientEmail}
            </div>
            <input className="mobile-fp-send" type="submit" value={lang.letstalk}></input>
          </div>
        </form>
        <div
          className="mobile-fp-button"
          onClick={() => {
            GA.sendEvent(`restart_visit`, {});
            click();
          }}
        >
          {lang.restart}
        </div>
        <div
          className="mobile-fp-button"
          onClick={() => {
            GA.sendEvent(`petmaxi_website_click`, {});
            window.open(url);
          }}
        >
          {lang.petmaxiwebsite}
        </div>
        <div className="mobile-fp-powered">
          <img
            className="mobile-fp-portugal"
            src="./assets/img/Logo_Portugal_2020.png"
            alt=""
          ></img>
          <p>powered by</p>
          <AliveVisionSVG />
        </div>
      </div>
    );
  }

  return (
    <div
      className="form-page"
      style={{
        height: '100vh',
        backgroundImage: `url(${imgSrc})`,
      }}
    >
      <div className="f-pm-focus"></div>
      <img className="f-pm-logo" src={logoSrc} alt="" />
      <h1 className="f-pm-title">{title}</h1>
      <p className="f-pm-desc">{description}</p>
      <div className="f-pm-buttons">
        <div
          className="f-pm-button"
          onClick={() => {
            GA.sendEvent(`restart_visit`, {});
            click();
          }}
        >
          <p>{lang.restart}</p>
          <ForwardSVG />
        </div>
        <div
          className="f-pm-button"
          onClick={() => {
            GA.sendEvent(`petmaxi_website_click`, {});
            window.open(url);
          }}
        >
          <p>{lang.petmaxiwebsite}</p>
          <ForwardSVG />
        </div>
      </div>
      <h1 className="f-title">{lang.thanks}</h1>
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="f-info">
          <input
            ref={nameRef}
            type="text"
            name="fname"
            placeholder={lang.name}
            onBlur={() => {
              GA.sendEvent(`fill_name`, {});
            }}
            required
          ></input>
          <input
            ref={emailRef}
            type="text"
            name="femail"
            placeholder={lang.email}
            onBlur={() => {
              GA.sendEvent(`fill_email`, {});
            }}
            required
          ></input>
        </div>
        <div className="f-loc">
          <input
            ref={locRef}
            type="text"
            name="floc"
            placeholder={lang.country}
            onBlur={() => {
              GA.sendEvent(`fill_location`, {});
            }}
            required
          ></input>
        </div>
        <textarea
          ref={messageRef}
          className="f-textarea"
          name="comment"
          form="usrform"
          placeholder={lang.writemessage}
          onBlur={() => {
            GA.sendEvent(`fill_message`, {});
          }}
          required
        ></textarea>
        <input className="f-send" type="submit" value={lang.letstalk}></input>
      </form>
      <img className="f-pm-portugal" src="./assets/img/Logo_Portugal_2020.png" alt=""></img>
      <div className="f-pm-powered">
        <p>powered by</p>
        <AliveVisionSVG />
      </div>
      <span className={sentMessage ? 'f-feedback active' : 'f-feedback'}>{lang.emailsent}</span>
      <div className="contact-info">{lang.contact}</div>
      <div
        className="email-info"
        onClick={() => {
          GA.sendEvent(`contact_click`, {});
          window.open(`mailto:${clientEmail}`);
        }}
      >
        {clientEmail}
      </div>
    </div>
  );
};

export default FormPage;
