import React from 'react';
import './App.css';
//import ExplorerContainer from './containers/ExplorerContainer';
import LandingPage from './containers/LandingPage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
//import MiniLandingPage from './containers/MiniLandingPage';

export const App = () => {
  //return <LandingPage />;
  return (
    <Router>
      <Switch>
        {/*<Route path="/landing-page">
          <MiniLandingPage />
  </Route>*/}
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </Router>
  );
};
