import React, { useState, FunctionComponent } from 'react';
import './NewMobileNavBar.css';
import { ReactComponent as GallerySVG } from '../icon/collections.svg';
import { ReactComponent as LeftSVG } from '../icon/left.svg';
import { ReactComponent as RightSVG } from '../icon/right.svg';
import { ReactComponent as PlaySVG } from '../icon/play.svg';
import { ReactComponent as PauseSVG } from '../icon/pause-2.svg';
import { ReactComponent as InfoSVG } from '../icon/info.svg';

interface NewMobileNavBarProps {
  nextScene: CallableFunction;
  prevScene: CallableFunction;
  sceneName: string;
  description: string;
  mode: string;
  thumbnails: { src: string; label: string; id: string; selected: boolean }[];
  changeScene: CallableFunction;
  playClick: CallableFunction;
}

const NewMobileNavBar: FunctionComponent<NewMobileNavBarProps> = ({
  nextScene,
  prevScene,
  sceneName,
  description,
  mode,
  thumbnails,
  changeScene,
  playClick,
}) => {
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  const [showThumbnails, setShowThumbnails] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const reset = () => {
    setShowInfo(false);
    setShowThumbnails(false);
  };

  const thumbs = thumbnails.map((t: any, index: number) => {
    const thumbnailClick = () => {
      reset();
      changeScene(t.id, '360');
    };
    return (
      <div
        key={'key-' + index + '-' + t.id}
        className={'new-thumb'}
        onClick={() => thumbnailClick()}
      >
        {t.selected && <div className="border"></div>}
        <img src={t.src} alt="NaN" />
        <p>{t.label}</p>
      </div>
    );
  });

  return mobile ? (
    <>
      {showInfo && (
        <div className="new-mob-info" onClick={() => reset()}>
          <p>{description}</p>
        </div>
      )}
      {showThumbnails && <div className="new-mob-thumbnails-menu">{thumbs}</div>}
      <div className="new-mob-nav-bar">
        <h1>{sceneName}</h1>
        <InfoSVG
          className={showInfo ? 'active' : ''}
          onClick={() => {
            setShowInfo(!showInfo);
            setShowThumbnails(false);
          }}
        />
        <GallerySVG
          className={showThumbnails ? 'active' : ''}
          onClick={() => {
            setShowInfo(false);
            setShowThumbnails(!showThumbnails);
          }}
        />
        <LeftSVG
          onClick={() => {
            reset();
            prevScene();
          }}
        />
        <RightSVG
          onClick={() => {
            reset();
            nextScene();
          }}
        />
        {mode !== 'autoplay' ? (
          <PlaySVG
            onClick={() => {
              reset();
              playClick();
            }}
          />
        ) : (
          <PauseSVG
            className="active"
            onClick={() => {
              reset();
              playClick();
            }}
          />
        )}
      </div>
    </>
  ) : null;
};

export default NewMobileNavBar;
