import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import './NewNavBar.css';
import { ReactComponent as PauseSVG } from '../icon/pause-2.svg';
import { ReactComponent as PlaySVG } from '../icon/play.svg';
import { ReactComponent as GallerySVG } from '../icon/collections.svg';
import { ReactComponent as InfoSVG } from '../icon/info.svg';
import navBarLanguage from '../languages/navbar.json';

interface NewNavBarProps {
  description: string;
  mode: string;
  thumbnails: { src: string; label: string; id: string; selected: boolean }[];
  changeScene: CallableFunction;
  playClick: CallableFunction;
  language: string | undefined;
}

const NewNavBar: FunctionComponent<NewNavBarProps> = ({
  description,
  mode,
  thumbnails,
  changeScene,
  playClick,
  language,
}) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showThumbnails, setShowThumbnails] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [overflow, setOverflow] = useState<boolean>(false);
  const infoRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (description) {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
    setOpenInfo(false);
  }, [description]);

  useEffect(() => {
    if (infoRef.current && !openInfo) {
      if (infoRef.current.scrollHeight > infoRef.current.clientHeight) {
        setOverflow(true);
      } else {
        setOverflow(false);
      }
    }
  }, [openInfo, description]);

  let lang = navBarLanguage.en;
  if (language) {
    switch (language) {
      case 'English':
        lang = navBarLanguage.en;
        break;

      case 'German':
        lang = navBarLanguage.de;
        break;

      default:
        lang = navBarLanguage.pt;
        break;
    }
  }

  const thumbs = thumbnails.map((t: any, index: number) => {
    const thumbnailClick = () => {
      reset();
      changeScene(t.id, '360');
    };
    return (
      <div
        key={'key-' + index + '-' + t.id}
        className={'new-thumbnail-item'}
        onClick={() => thumbnailClick()}
      >
        {t.selected && <div className="border"></div>}
        <img src={t.src} alt="NaN" />
        <p>{t.label}</p>
      </div>
    );
  });

  const reset = () => {
    setShowInfo(false);
    setShowThumbnails(false);
  };

  let infoClass = 'new-info-p';
  if (showInfo) infoClass += ' active';
  if (showThumbnails) infoClass += ' open-thumbnails';
  if (openInfo) infoClass += ' open-info';

  let infoIconClass = 'new-info';
  if (description) {
    if (showInfo) infoIconClass += ' active';
  } else {
    infoIconClass += ' inactive';
  }

  return (
    <>
      {/*(showInfo || showThumbnails) && (
        <div
          className="new-nav-background"
          onClick={() => {
            reset();
          }}
        ></div>
        )*/}
      <div className={infoClass}>
        {overflow && !openInfo ? (
          <div onClick={() => setOpenInfo(true)} className="up">
            [{lang.readmore}]
          </div>
        ) : null}
        {overflow && openInfo ? (
          <div onClick={() => setOpenInfo(false)} className="up">
            [{lang.readless}]
          </div>
        ) : null}
        <p ref={infoRef}>{description}</p>
      </div>
      <div className={showThumbnails ? 'new-thumbnails-menu active' : 'new-thumbnails-menu'}>
        {thumbs}
      </div>
      <div className={showThumbnails ? 'new-bar open-thumbnails' : 'new-bar'}>
        <div
          className={infoIconClass}
          onClick={() => {
            if (description) {
              reset();
              setShowInfo(!showInfo);
            }
          }}
        >
          <InfoSVG />
        </div>
        <div className="new-play-pause" onClick={() => playClick()}>
          {mode !== 'autoplay' ? <PlaySVG /> : <PauseSVG />}
        </div>
        <div
          className={showThumbnails ? 'new-thumbnails active' : 'new-thumbnails'}
          onClick={() => {
            reset();
            setShowThumbnails(!showThumbnails);
          }}
        >
          <GallerySVG />
        </div>
      </div>
    </>
  );
};

export default NewNavBar;
