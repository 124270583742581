declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export interface GAConfig {
  id: string;
}

export default class GA {
  private static id: string;
  private static gtag: CallableFunction;

  static config = (id: string) => {
    if (!GA.id) {
      const pattern = /G-\w{10}/;
      if (id.length === 12 && pattern.test(id)) {
        GA.id = id;

        const js = document.createElement("script");
        js.type = "text/javascript";
        js.async = true;
        js.src = `https://www.googletagmanager.com/gtag/js?id=${GA.id}`;
        document.body.append(js);

        window.dataLayer = window.dataLayer || [];
        GA.gtag = function gtag() {
          window.dataLayer.push(arguments);
        };
        try {
          GA.gtag("js", new Date());
          GA.gtag("config", GA.id, {
            send_page_view: false,
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        console.error(
          "Check if Google Analytics ID follows the pattern G-XXXXXXXXXX where X is a number."
        );
      }
    }
  };

  static pageView = (location: string, title?: string) => {
    if (GA.id && GA.gtag) {
      GA.gtag("event", "page_view", {
        page_title: title ? title : document.title,
        page_location: location,
      });
    } else {
      console.error("Google Analytics not initialized");
    }
  };

  static sendEvent = (event: string, body: Object) => {
    if (GA.id && GA.gtag) {
      GA.gtag("event", event, body);
    } else {
      console.error("Google Analytics not initialized");
    }
  };
}
