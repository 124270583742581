import React, { RefObject } from 'react';
import './modalVideo.template.css';
import { ModalHotspotProps } from '../../types/hotspot';
import { ReactComponent as CloseSVG } from '../../icon/close.svg';

interface ModalVideoProps extends ModalHotspotProps {
  active: boolean;
  close: CallableFunction;
}

export default class Modal1 extends React.Component<ModalVideoProps> {
  iframeRef: RefObject<HTMLIFrameElement> = React.createRef();

  componentDidUpdate = () => {
    if (this.iframeRef.current) {
      this.iframeRef.current.onpause = () => {
        console.log('paused');
      };
    }
  };

  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let videoFrame;
    if (this.props.active) {
      if (this.props.info.youtube) {
        videoFrame = (
          <iframe
            ref={this.iframeRef}
            title={this.props.info.description}
            src={`${this.props.info.videoSrc}?controls=1&autoplay=1&autorepeat=1&mute=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      } else {
        if (mobile && this.props.info.mobileVideo) {
          videoFrame = (
            <video autoPlay preload="auto" controls playsInline={true}>
              <source src={this.props.info.mobileVideo} type="video/mp4" />
            </video>
          );
        } else {
          videoFrame = (
            <video autoPlay preload="auto" controls playsInline={true}>
              <source src={this.props.info.videoSrc} type="video/mp4" />
            </video>
          );
        }
      }
    }
    /*const videoFrame = this.props.active ? (
      <iframe
        ref={this.iframeRef}
        title={this.props.info.description}
        src={`${this.props.info.videoSrc}?controls=1&autoplay=1&autorepeat=1&mute=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    ) : null;*/

    if (mobile) {
      return (
        <div className="mobile-modal-video">
          <CloseSVG
            onClick={() => {
              this.props.close();
            }}
          />
          {videoFrame}
          <p>{this.props.info.title}</p>
        </div>
      );
    } else {
      return (
        <div className={'modal-video'}>
          {videoFrame}
          <p>{this.props.info.title}</p>
        </div>
      );
    }
  }
}
