import React, { FunctionComponent } from 'react';
import './NavigationArrow.css';
import { ReactComponent as NextSpaceSVG } from '../icon/back.svg';
import { ReactComponent as BackOvalSVG } from '../icon/back-oval.svg';

interface NavigationArrowProps {
  nextScene: CallableFunction;
  prevScene: CallableFunction;
}

const NavigationArrow: FunctionComponent<NavigationArrowProps> = ({ nextScene, prevScene }) => {
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  const mobileClass = mobile ? 'mobile ' : '';

  return (
    <>
      <div className={mobileClass + 'left-container'}>
        <NextSpaceSVG className="left-arrow" onClick={() => prevScene()} />
        {!mobile && <BackOvalSVG className="back-oval" />}
      </div>
      <div className={mobileClass + 'right-container'}>
        <NextSpaceSVG className="right-arrow" onClick={() => nextScene()} />
        {!mobile && <BackOvalSVG className="back-oval" />}
      </div>
    </>
  );
};

export default NavigationArrow;
