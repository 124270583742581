import React, { FunctionComponent } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { GlobalVars } from '../libs/utils';
import { ViewerJSON } from '../types/viewer3D';
import Pulse from './pulse';
import './viewer.css';
const Viewer3D = require('int_sdk_viewer3d').default;

interface ViewerProps {
  selectedSpaceId: string | string[];
  onDoubleClick: CallableFunction;
  activate: CallableFunction;
  viewerMappingPath: string;
  show: boolean;
  live?: boolean;
}

const ModelViewer: FunctionComponent<ViewerProps> = ({
  selectedSpaceId,
  onDoubleClick,
  activate,
  viewerMappingPath,
  show,
  live,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [pulse, setPulse] = useState<{ x: number; y: number } | null>(null);
  const viewerRef = useRef<any>(null);
  //const extRef = useRef<any>(null);

  const viewerMapping: ViewerJSON = require(`../JSON/${viewerMappingPath}`);

  const dataSource = {
    type: 'SceneDataSource',
    url: './models/pbs/',
    path: 'data.json',
    enableWireframe: false,
  };

  useEffect(() => {
    if (live && GlobalVars.socket) {
      GlobalVars.socket.on('change3DCamera', (position: any) => {
        loaded &&
          viewerRef.current &&
          viewerRef.current.api.getCameraAPI().setPosition(position, {
            type: 'linear',
            duration: 0,
          });
      });
      GlobalVars.socket.on('highlight3D', (pos: { x: number; y: number }) => {
        setPulse(pos);
        setTimeout(() => {
          setPulse(null);
        }, 1500);
      });
    }
  }, [live, loaded]);

  useEffect(() => {
    if (!show) {
      viewerRef.current &&
        loaded &&
        viewerRef.current.api.getCameraAPI().reset({
          type: 'linear',
          duration: 1000,
        });
    }
  }, [show, loaded]);

  useEffect(() => {
    const nodeAPI = viewerRef.current && viewerRef.current.api.getNodeAPI();
    nodeAPI && loaded && nodeAPI.deselectAllNodes();
    viewerMapping.nodes.forEach((node: any) => {
      if (!Array.isArray(selectedSpaceId) && node.scene === selectedSpaceId) {
        const selNode = nodeAPI.getNode(node.node);
        nodeAPI && loaded && nodeAPI.selectNode(selNode, false);
      }
    });
  }, [selectedSpaceId, loaded, viewerMapping.nodes]);

  return (
    <div
      style={show ? { zIndex: loaded ? -1 : 0, pointerEvents: 'none' } : {}}
      className="container-viewer"
      onPointerDown={() => loaded && activate()}
      onDoubleClick={(e: any) => {
        if (live && GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
          GlobalVars.socket.emit('highlight3D', {
            x: (e.clientX * 100) / window.innerWidth,
            y: (e.clientY * 100) / window.innerHeight,
          });
        }
      }}
    >
      {pulse && (
        <div className="pulse-3d" style={{ top: `${pulse.y}%`, left: `${pulse.x}%` }}>
          <Pulse stop={() => {}} />
        </div>
      )}
      <Viewer3D
        ref={viewerRef}
        dataSource={dataSource}
        renderer={{ showStats: false, ratio: 2, enabled: !show }}
        camera={{
          loadFraming: false,
          fov: 75,
          enablePan: false,
          minDistance: 100,
          scrollZoomToCursor: false,
          rotateSpeed: 0.5,
          initialPosition: { x: 100, y: 100, z: 100 },
          onCameraChange: (api: any) => {
            if (
              live &&
              GlobalVars.room !== undefined &&
              GlobalVars.socket &&
              GlobalVars.controller
            ) {
              GlobalVars.socket.emit('change3DCamera', api.getCameraAPI().getPosition());
            }
          },
        }}
        light={{ intensity: 1.5 }}
        scenes={{
          onSceneReady: (api: any, scene: any) => {
            setLoaded(true);
          },
        }}
        nodes={{
          selectToIsolate: false,
          doubleClickToLoad: false,
          onNodeSelect: (node: any, source: any, nodeList: any) => {},
          onNodeDeselect: (node: any, source: any, nodeList: any) => {},
          onNodeHover: (node: any) => {},
          onNodeUnhover: (node: any) => {},
          onNodeDoubleClick: (api: any, node: any) => {
            const selNode = viewerMapping.nodes.find((n) => n.node === node.name);
            if (selNode) {
              api.getCameraAPI().setPosition(selNode.zoom, {
                type: 'linear',
                duration: 1000,
                onFinish: () => onDoubleClick(selNode.scene),
              });
            } else {
              api.getCameraAPI().reset({
                type: 'linear',
                duration: 1000,
              });
            }
          },
        }}
      />
    </div>
  );
};

export default ModelViewer;
