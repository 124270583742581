import React from 'react';
import ReactGA from 'react-ga';
import './modalLink.template.css';
import { ModalHotspotProps } from '../../types/hotspot';
import { ReactComponent as CloseSVG } from '../../icon/close.svg';
import { getNameById } from '../../libs/marzipano';
import GA from '../../libs/ga';

interface ModalExpoProps extends ModalHotspotProps {
  close: CallableFunction;
}

interface ModalLinkState {
  scroll: boolean;
}

export default class Modal1 extends React.Component<ModalExpoProps> {
  state: ModalLinkState = {
    scroll: true,
  };

  visitLink = () => {
    ReactGA.event({
      category: 'Modal Hotspot',
      action: `Visit Link`,
      label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
    });
    const gaLabel = `modal_visit_link_${getNameById(this.props.sceneId)}[${this.props.info.title}`;
    GA.sendEvent(gaLabel.toLowerCase(), {});
    window.open(this.props.info.link);
  };

  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    if (mobile) {
      return (
        <div className="mobile-modal-link">
          <h1>
            {this.props.info.title}
            <CloseSVG onClick={() => this.props.close()} />
          </h1>
          <div
            className="mobile-content-link"
            onScroll={() => {
              this.setState({ scroll: false });
            }}
          >
            <div className="mobile-modal-img">
              <img src={this.props.info.src} alt=""></img>
            </div>
            <div className="mobile-text">
              <h1>{this.props.info.subtitle}</h1>
              <p>{this.props.info.description}</p>
              {this.props.info.linkLabel && (
                <div
                  onClick={() => {
                    this.visitLink();
                  }}
                >
                  {this.props.info.linkLabel}
                </div>
              )}
            </div>
          </div>
          <div className={this.state.scroll ? 'scroll active' : 'scroll'}>
            <div className="scroll-arrow"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={'modal-link'}>
          <div className="link-title">
            <h1>{this.props.info.title}</h1>
            <div className="link-close">
              <CloseSVG onClick={() => this.props.close()} />
            </div>
          </div>
          <div className="content-link">
            <div className="link-img">
              <img src={this.props.info.src} alt=""></img>
            </div>
            <div className="link-text">
              <h1>{this.props.info.subtitle}</h1>
              <p>{this.props.info.description}</p>
              {this.props.info.linkLabel && (
                <div
                  onClick={() => {
                    this.visitLink();
                  }}
                >
                  {this.props.info.linkLabel}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
